import React, { Component } from 'react';
import { Container, Row, Col, Card,Image } from 'react-bootstrap';
import './_preloader.scss'

class PreloaderThree extends Component {
    // PreLoaderElement() {
    //     return new Promise(resolve => setTimeout(resolve, 2000))
    // }

    // componentDidMount() {
    //     this.PreLoaderElement().then(() => {
    //         const preloaderEle = document.getElementById('preloader')
    //         if (preloaderEle) {
    //             // fade out
    //             preloaderEle.classList.add('available')
    //             setTimeout(() => {
    //                 // remove from DOM
    //                 preloaderEle.outerHTML = ''
    //             }, 1000)
    //         }
    //     })
    // }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Image src={require('../../../images/rizee_loading.png')} className="loading_img" alt="Lock" />
                    </Col>
                </Row>
            </Container>
           
        );
    }
}

export default PreloaderThree;